
import { Directive, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Directive({ selector: '[appDirTest]' })
export class DirTestDirective {
  @Output() refresh = new EventEmitter<number>();
  constructor(private element: ElementRef) {
    setInterval(() => {
      this.refresh.emit( Math.random() );
      console.log('emmitted');
    }, 1000);
  }
}


// <div appDirTest (refresh)="mySub($event)"></div>
// mySub = (e) => {
//   console.log('-----');
// }
