import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {BaseHttpService} from './BaseHttpService';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie';
import {environment} from '../../environments/environment';

export interface LoginResponseModel {
  id: number;
  token: string;
  email: string;
  name: string;
}

@Injectable()
export class AuthorizationService extends BaseHttpService {
  private static readonly LOGIN_URL = 'v0/auth-users/login/';
  static emailPattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(private httpClient: HttpClient, private router: Router, private cookieService: CookieService) {
    super(httpClient);
  }

  isLoggedIn(): boolean {
    return !(!this.cookieService.get('token'));
  }

  login(email: string, password: string): Observable<HttpResponse<LoginResponseModel>> {
    return this.post(AuthorizationService.LOGIN_URL, {username: email, password: password}).pipe(
      map((res) => {
        if (!res.token) {return res; }
        this.deleteCookies();
        if (res.is_staff) {
          this.setCookies(res);
          this.router.navigate(['/admin/companies']);
        } else {
          this.setCookies(res);
          this.router.navigate(['/home']);
        }
        return res;
      })
    );
  }

  logOut() {
    this.deleteCookies();
    this.router.navigate(['/login']);
  }

  setCookies(res) {
    this.cookieService.put('token', res.token);
    this.cookieService.put('is_staff', res.is_staff);
    this.cookieService.put('name', res.name);
  }

  deleteCookies() {
    this.cookieService.remove('token');
    this.cookieService.remove('is_staff');
    this.cookieService.remove('name');
  }
}
