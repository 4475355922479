import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {AdminAuthGuardService} from './services/guards/admin-auth-guard.service';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'admin', loadChildren: './admin/admin.module#AdminModule', canLoad: [AdminAuthGuardService]},
  {path: 'home', loadChildren: './components/outbound/outbound.module#OutboundModule'},
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: '**', redirectTo: '/home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule],
  providers: [AdminAuthGuardService]
})
export class AppRoutingModule {
}
