import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RoutingState} from '../../../services/routing-state';
import {CookieService} from 'ngx-cookie';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent implements OnInit {
  private previousRoute: string;

  constructor(private router: Router, private routingState: RoutingState, private cookieService: CookieService) {}

  ngOnInit() {
    this.previousRoute = this.routingState.getPreviousUrl();
  }

  goToPreviousUrl(): void {
    if (this.previousRoute !== '/index') {
      this.router.navigate([this.previousRoute]);
    } else if (!(!this.cookieService.get('token')) && this.cookieService.get('is_staff') === 'true') {
      this.router.navigate(['/admin/companies'], { queryParams: { page: `1`}, queryParamsHandling: 'merge' });
    } else if (!(!this.cookieService.get('token')) && this.cookieService.get('is_staff') === 'false') {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/home']);
    }
  }
}
