import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthorizationService} from '../../services/authorization.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  constructor(private fb: FormBuilder, private authService: AuthorizationService) { }

  ngOnInit() {
    this.createForm();
  }

  createForm(): void {
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(AuthorizationService.emailPattern)])],
      password: ['', Validators.compose([Validators.required, Validators.maxLength(40)])]
    });
  }

  login(): void {
    const controls = this.loginForm.controls;
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.get('email').value, this.loginForm.get('password').value)
        .subscribe((response: any) => {
          if (!response.token) {
            this.loginForm.setErrors({failed: true});
            return;
          }
        }, () => {
          this.loginForm.setErrors({failed: true});
          return;
      });
    } else {
      Object.keys(controls)
        .forEach(controlName => {
          controls[controlName].markAsTouched();
          // controls[controlName].markAsDirty();
        });
      return;
    }
  }

}
