import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppBootstrapModule} from './app-bootstrap.module';
import {PageNotFoundComponent} from './components/ui/page-not-found/page-not-found.component';
import {RoutingState} from './services/routing-state';
import {HeaderModule} from './components/header/header.module';
import {DirTestDirective} from './directives/dir-test.directive';
import {CookieModule, CookieOptionsProvider, CookieService} from 'ngx-cookie';
import {LoginComponent} from './components/login/login.component';
import {AuthorizationService} from './services/authorization.service';
import {AdminAuthGuardService} from './services/guards/admin-auth-guard.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    DirTestDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    AppBootstrapModule,
    HeaderModule,
    CookieModule.forRoot()
  ],
  providers: [
    AuthorizationService,
    AdminAuthGuardService,
    RoutingState,
    CookieService,
    CookieOptionsProvider,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
