import {Injectable} from '@angular/core';
import {CanActivate, CanLoad, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie';
import {AuthorizationService} from '../authorization.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuardService implements CanActivate, CanLoad {

  constructor(private authService: AuthorizationService, private router: Router, private cookieService: CookieService) { }

  canActivate() {
    if (this.isAdmin()) {
      return true;
    } else {
      return false;
    }
  }

  canLoad() {
    if (this.isAdmin()) {
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }

  isAdmin() {
    return !(!this.cookieService.get('token')) && this.cookieService.get('is_staff') === 'true';
  }
}
