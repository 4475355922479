import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie';
import {AuthorizationService} from '../../services/authorization.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  userName: string;

  constructor(private router: Router, private cookieService: CookieService, private authService: AuthorizationService) {
  }

  ngOnInit() {
    this.userName = this.cookieService.get('name') === 'null' ? 'Admin' : this.cookieService.get('name');
  }

  logOut(): void {
    this.authService.logOut();
  }
}
